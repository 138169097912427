import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../../common/Drawer/AgentListDrawerModal";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";

const Index = ({ opendrawer, setOpenDrawer, MTRef, partyList }) => {
  return (
    <Drawer state={opendrawer} setState={setOpenDrawer} label="Drawer">
      <Table {...{ setOpenDrawer, MTRef }} data={partyList} />
    </Drawer>
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({ setOpenDrawer, MTRef, data }) => {
  return (
    <div>
      <Header>
        <Heading style={{ marginBottom: "unset" }}>Agent Details</Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => setOpenDrawer(false)}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        {data.map((item, index) => (
          <TableWithAccordion
            key={index}
            title={`${item?.party?.name}'s Agent details (${_.capitalize(
              item?.partyRole
            )})`}
            {...{ MTRef, item }}
          />
        ))}
      </AccordionContainer>
    </div>
  );
};

function TableWithAccordion({ MTRef, title, item }) {
  const classes = useStyles();

  const columns = [
    {
      field: "user.name",
      title: "Agent Name",
      sorting: false,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      render: (rowData) => (
        <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      ),
    },
    {
      field: "user.mobile",
      title: "Phone",
      sorting: false,
    },
  ];

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {item?.party?.agents?.length ? (
            <CustomTable
              pluralTitle="Agents"
              singularTitle="Agent"
              {...{
                columns,
                MTRef,
              }}
              data={item?.party?.agents}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No Agents Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;
