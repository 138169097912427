const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      login: "admin/sessions",
      profile: "me",
    },
    casemanager: {
      index: "admin/case-managers",
      stats: "casemanager/case-stats",
      cases: "casemanager/cases",
    },
    case: {
      caseIndex: "cases",
      case: "casemanager",
      index: "casemanager/cases",
      stats: "stats",
      assignCase: "admin/cases",
      caseStats: "casemanager/case-stats",
      uploads: "casemanager/uploads",
      saveSummary: "casemanager/summary",
      uploadAgreement: "negotiationAgreement",
      caseAgreementUpload: "casemanager/uploads/case",
      uploadStats: "casemanager/stats",
      case_document_uploads: "casemanager/case-document-uploads",
      uploadCase: "casemanager/uploads/case",
      getCaseDocuments: "casemanager/uploads",
      pendingAction: "casemanager/activities",
      offlinePayment: "payment-capture-offline",
      comments: "/comments",
      draft: "/cases/drafts",
      selectDraft: "/selects/drafts",
      select: "/selects/case",
      publishMediator: "admin/publishMediators",
      transcripts: "/transcripts",
      reOpenCase: "/reOpenCase",
    },
    meeting: {
      meetings: "/meetingJoin",
      mettingcase: "/getMeetingDet",
      recordControl: "/recordControl",
      meetingaccessadmin : "/meetingControl",
      meetingDet: "caseMeetingDet",
      meetingRecordings: "/meetingRecordings",
      getAudioFile: "/getAudioFile",
      getMeetingAudioDetails: "/getMeetingAudioDetails",
      genericMeetingJoin: "/genericMeetingJoin",
      genericMeetingRecordings: "/genericMeetingRecordings",
      genericMeetingUrl: "/genericMeetingUrl",
      genericMeeting: "/genericMeeting",
    },
    cart: {
      orders: "/orders",
    },
    payment: {
      payments: "/payments",
    },
    user: {
      index: "casemanager/users",
      update: "casemanager/update-user",
      invitedMediator: "/casemanager/invited/mediators",
    },
    systemConfig: "system-configs",
    s3: "s3-url",
  },
};

export default ApiConfig;
