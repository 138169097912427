import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomInputField } from "../../common/FormInputs";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../common/Buttons";

const App = (adminId) => {
  const [copied, setCopied] = useState(false);
  const [data, setData] = useState();
  const id = adminId?.adminId

  useEffect(() => {
    async function getMeetingLink() {
      try {
        const meetingurl = await CaseService.getMeetingLink();
        setData(meetingurl);
      } catch (error) {}
    }
    getMeetingLink();
  }, []);

  async function meetingJoinAdmin(id) {
    try {
      const meetingurl = await CaseService.joinMeeting(id);
      window.open(meetingurl, "_blank");
      return;
    } catch (error) {}
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <HalfWidth>
        <CustomInputField
          type={"text"}
          value={data}
          disabled={true}
          placeholder="Type Something to Copy"
          variant="outlined"
        />
      </HalfWidth>
      <ButtonContainer style={{ margin: 0, marginLeft: 20 }}>
        <CopyToClipboard text={data} onCopy={() => setCopied(true)}>
          <PrimaryCTAButton>Click to Copy</PrimaryCTAButton>
        </CopyToClipboard>
      </ButtonContainer>
      <ButtonContainer style={{ margin: 0, marginLeft: 20 }}>
        <PrimaryCTAButton onClick={() => meetingJoinAdmin(id)}>
          Join Meeting
        </PrimaryCTAButton>
      </ButtonContainer>
      {copied}
      {/* {copied ? <p>Copied !</p> : null} */}
    </div>
  );
};

export default App;

const HalfWidth = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
