import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import _ from "lodash";
import styled from "styled-components";
import { sidebar_options } from "./constants";
import { navigate } from "@reach/router";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import labels from "../../../helpers/labels.json";

const drawerWidth = 273;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerContainer: {
    overflow: "auto",
    height: "100%",
  },
  inactiveListItem: {
    padding: "0px",
    borderBottom: "1px solid #e1e3ee",
    paddingLeft: 47,
    height: 57,
  },
  activeListItem: {
    padding: "0px",
    paddingLeft: 47,
    height: 57,
    backgroundColor: "#293461 !important",
  },

  nested: {
    margin: "0 0 15px 0",
    marginLeft: "60px",
    width: "200px",
    height: "38px",
    borderRadius: "5px",
    transition: "300ms",
    boxSizing: "borderbox",
    "& span": {
      transition: "250ms",
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      borderLeft: "3px solid #fff",
      "& span": {
        color: "#fff",
      },
    },
  },
  wrapperInner: {
    backgroundColor: "#293461",
  },
  inactiveNested: {
    backgroundColor: "transparent",
  },
  activeNested: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderLeft: "3px solid #fff",
  },
}));

export default function Sidebar({ selectedItem, selectedSubItem, stats }) {
  const classes = useStyles();
  const [expand, setExpand] = useState(true);

  const performanceNavigation = (data) => {
    if (data.hasOwnProperty("listItems") && data.title === selectedItem) {
      if (data.title === labels.cases && checkExpansion()) {
        setExpand(!expand);
      } else {
        navigate(data.path);
      }
    } else {
      navigate(data.path);
    }
  };

  const checkExpansion = () => {
    if (stats?.negotiationCount && stats?.mediationCount) {
      return true;
    }
    if (stats?.mediationCount && stats?.arbitrationCount) {
      return true;
    }
    if (stats?.arbitrationCount && stats?.negotiationCount) {
      return true;
    }
    return false;
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {sidebar_options.map((data, index) => (
            <React.Fragment key={index}>
              <ListItem
                className={
                  data.title === selectedItem
                    ? classes.activeListItem
                    : classes.inactiveListItem
                }
                button
                onClick={() => performanceNavigation(data)}
                key={data.title}
              >
                <ListItemIcon>
                  <Icon
                    src={
                      data.title === selectedItem ? data.icon : data.inactive
                    }
                  />
                </ListItemIcon>
                <ListItemText selected={data.title === selectedItem}>
                  <div className="row flex center-center">
                    <div className="flex flex-1">{_.startCase(data.title)}</div>
                    <div className="mr30 flex">
                      {data.hasOwnProperty("listItems") && checkExpansion() && (
                        <>
                          {expand && data.title === selectedItem ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </ListItemText>
              </ListItem>
              {data.hasOwnProperty("listItems") && checkExpansion() && (
                <Collapse
                  in={data.title === selectedItem && expand}
                  timeout="auto"
                  unmountOnExit
                  className={classes.wrapperInner}
                >
                  <List
                    style={{
                      borderBottom: `solid 0.3px rgba(255, 255, 255, 0.12)`,
                    }}
                    component="div"
                    disablePadding
                  >
                    {data.listItems.map((li, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          key={index}
                          button
                          className={`${classes.nested} ${
                            li.title === selectedSubItem
                              ? classes.activeNested
                              : classes.inactiveNested
                          }`}
                          onClick={() => navigate(li.path)}
                        >
                          <ListItemIcon>
                            <Icon
                              src={
                                li.title === selectedSubItem
                                  ? li.icon
                                  : li.inactive
                              }
                            />
                          </ListItemIcon>
                          <ListItemText selected={li.title === selectedSubItem}>
                            {_.startCase(li.title)}
                          </ListItemText>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Drawer>
  );
}

const ListItemText = styled.span`
  font-family: openSans-bold, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  color: ${({ selected }) => (selected ? "#ffffff" : "#acb1c2")};
`;

const Icon = styled.img`
  object-fit: contain;
  width: 16px;
  height: auto;
  color: #acb1c2;
`;

const ListItemIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
