import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { CustomInputField } from "../../common/FormInputs";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";

export default function RefundModal({
  editName,
  editNameRecord,
  setEditName,
  setEditNameRecord,
  recordData,
  MTRef
}) {
  let recordingName = recordData?.recordingName;
  let recordName = recordData?.name;
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [name, setName] = useState(recordingName ? recordingName : recordName);
  let id = recordData?.transcriptId;

  useEffect(() => {
    if (editName) {
      setName(recordName);
    } else if (editNameRecord) {
      setName(recordingName?.length ? recordingName : recordName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editName, editNameRecord]);

  const addRecordingName = async value => {
    try {
      const payload = {
        recordingName: value,
        meetingId: recordData?.meetingID,
        recordId: recordData?.recordID
      };
      setLoader({ state: true, message: `Uploading Recording Name` });
      const res = await CaseService.addTranscript(payload);
      if (res) {
        enqueueSnackbar("Recoring Name Updated Successfully", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setEditName(false);
      MTRef.current.onQueryChange("");
    }
  };

  const createRecordingName = async value => {
    try {
      const payload = {
        recordingName: value
      };
      setLoader({ state: true, message: `Uploading Recording Name` });
      const res = await CaseService.updateTranscript(payload, id);
      if (res) {
        enqueueSnackbar("Recoring Name Updated Successfully", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setEditNameRecord(false);
      MTRef.current.onQueryChange("");
    }
  };

  return recordData?.transcriptId ? (
    <Modal open={editNameRecord}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Recording Name</Heading>
            <CloseModal
              onClick={() => setEditNameRecord(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <div>
              <Label>Enter Recording Name</Label>
              <CustomInputField
                variant="outlined"
                value={name}
                required={true}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={() => setEditNameRecord(false)}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={!name}
                onClick={() => createRecordingName(name)}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  ) : (
    <Modal open={editName}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Recording Name</Heading>
            <CloseModal
              onClick={() => setEditName(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <div>
              <Label>Enter Recording Name</Label>
              <CustomInputField
                variant="outlined"
                value={name}
                required={true}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={() => setEditName(false)}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={!name}
                onClick={() => addRecordingName(name)}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.COLOR_DARK};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
