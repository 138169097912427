/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { replaceHtmlTag } from "./functions";
// import { states } from "./constants";

const MOBILE_REGEX = /^\s*(?:\+?(\d{2}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const PASSWORD_RULE = /^(?=.*[a-z])(?=.*[0-9])/;

// function validatePinCodeByState(state, pin) {
//   if (state && pin) {
//     const found = states.find((st) => st.value === state);
//     const pinStart = pin?.toString().substring(0, 2);
//     if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

export const FAQValidationSchema = Yup.object({
  sections: Yup.array(
    Yup.object({
      question: Yup.string().trim().required("Question is mandatory"),
      answer: Yup.string().trim().required("Answer is mandatory"),
    })
  ),
});

export const congurationEditorSchema = Yup.object({
  sections: Yup.array(
    Yup.object({
      question: Yup.string().trim().required("Question is mandatory"),
      answer: Yup.string()
        .trim()
        .test("test", "Answer is required", function (value) {
          return replaceHtmlTag(value)?.length > 0;
        }),
    })
  ),
});
export const TutorialValidationSchema = Yup.object({
  tutorialUrl: Yup.string().trim().required("Tutorial Video is mandatory"),
});

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string().email("Please enter valid email").required(),
  password: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Password is required"),
});

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const createCaseManager = Yup.object({
  name: Yup.string("Please enter valid phone number")
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email id is Required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
  type: Yup.string(),
  password: Yup.string()
    .matches(
      PASSWORD_RULE,
      "Password should have a minimum of 1 digit and 1 alphabet"
    )
    .min(6, "Password must be minimum 6 characters")
    .test("Required", "Password is required", function (value) {
      return value || this.parent?.type === "EDIT";
    }),
  viewInformation: Yup.bool().oneOf([true], "Viewing Information is required"),
});

export const EditProfileSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  tagLine: Yup.string().trim().required("Tagling is required"),
  qualification: Yup.string().trim().required("Qualification is required"),
  experience: Yup.string().trim().required("Experience is required"),
  languagesKnown: Yup.string().trim().required("Languages is required"),
  location: Yup.string().trim().required("Location is required"),
  affiliatedOrg: Yup.string()
    .trim()
    .required("Affiliated Organization is required"),
  specialities: Yup.array().of(
    Yup.string().trim().required("Speciality is Required")
  ),
  shortBio: Yup.string().trim().required("Short bio is required"),
  lastHeldPosition: Yup.string().trim().required("Last Held Position is required"),
});

export const UpdateCaseSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Please enter valid email"),
  phone: Yup.string()
    .max(10, "Enter a valid mobile number")
    .min(10, "Enter a valid mobile number")
    .required("Phone number is required"),
});

export const AudioSplit = Yup.object({
  audioFileName: Yup.string().trim().required("Audio File Name is required"),
});