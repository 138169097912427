import React from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { CustomRadioGroup } from "../../../common/FormInputs";
import { Formik } from "formik";
import CaseService from "../../../../services/CaseService";
import { useSnackbar } from "notistack";
import useLoader from "../../../../hooks/useLoader";
import { getErrorMessage } from "../../../../helpers/functions";
import SystemService from "../../../../services/SystemService";

export default function RefundModal({
  openPublish,
  setOpenPublish,
  user,
  setUser,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  

  function prefillValues(e, setFieldValue) {
    setFieldValue("role", e.target.value);
  }

  async function onFormSubmit(values, res, params, publishAs) {
    try {
      setLoader({ state: true, message: "Publish Neutral's Info..." });
      console.log("Dataaaa", res);
      const { role } = values;

      let payload = {
        publishAs: role,
        details: {
          id: user.id,
          name: user.name,
          image: user.avatarUrl
            ? user.avatarUrl
            : "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/1/1631786889217-76df4931-80b9-4fa4-a0bd-6389c19d97ee/user.png",
          qualification: user.qualification,
          lastHeldPosition: user.lastHeldPosition,
          yearsOfExperience: user.experience,
          languagesKnown: user.languagesKnown,
          affiliatedOrganisation: user.affiliatedOrg,
          areasOfExpertise: user.specialities,
          location: user.location,
          chronicles: user.shortBio,
          otherInterests: "",
        },
      };
      console.log("payload", payload);

      const response = await CaseService.publishMediator(payload);
      if (response.config) {
        enqueueSnackbar("Updated Mediators Info", {
          variant: "success",
        });
      }
      if (response) {
        const responseData = await SystemService.setProfile(user.id);
        setUser(responseData.user);
        console.log(responseData);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenPublish(false);
    }
  }

  return (
    <Modal open={openPublish}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Publish Neutral</Heading>
          <CloseModal
            onClick={() => setOpenPublish(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <Formik
          initialValues={{
            name: user?.name || "",
            email: user?.email || "",
            mobile: user?.mobile || "",
            role: user?.role || "",
            publishedAs: user.publishedAs || "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            isValid,
            handleBlur,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <NoteContainer>
                <CustomRadioGroup
                  radioInputs={[
                    {
                      label: "Mediator",
                      value: "mediator",
                    },
                    {
                      label: "Arbitrator",
                      value: "arbitrator",
                    },
                    {
                      label: "Both",
                      value: "both",
                    },
                  ]}
                  name={"role"}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    prefillValues(e, setFieldValue);
                  }}
                  {...{ values }}
                />
              </NoteContainer>
              <ButtonWrapper
                style={{
                  margin: "auto",
                  width: "418px",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "52px",
                }}
              >
                <PrimaryOutlinedCTAButton
                  style={{
                    height: "48px",
                    width: "192px",
                    marginRight: 20,
                  }}
                  onClick={() => setOpenPublish(false)}
                >
                  Cancel
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  type={"submit"}
                  // disabled={
                  //   !isValid || values.publishedAs === "both"
                  //     ? values.publishedAs
                  //     : values.publishedAs === "mediator"
                  //     ? values.role === "mediator" || values.role === "both"
                  //     : values.publishedAs === "arbitrator"
                  //     ? values.role === "arbitrator" || values.role === "both"
                  //     : values.publishedAs
                  // }
                  onClick={handleSubmit}
                  style={{ height: "48px", width: "192px" }}
                >
                  Publish
                </PrimaryCTAButton>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
      </DrawerContainer>
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
`;

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
  & .MuiFormControlLabel-root {
    font-family: openSans-ExtraBold, san-serif;
    & .MuiTypography-body1 {
      font-family: openSans-ExtraBold, san-serif;
    }
  }
  & .sub-heading {
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 16px;
    color: ${COLORS.PRIMARY_BLACK};
  }
  & .history-row {
    display: flex;
    border-bottom: solid 0.5px #acb1c2;
    & .MuiCheckbox-colorPrimary.Mui-disabled {
      cursor: not-allowed !important;
    }
  }
  & .content {
    flex: 1;
    padding: 18px 0px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    font-weight: 600;
    color: ${COLORS.PRIMARY_BLACK};
  }
`;
