import React, { useState, useRef } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import queryString from "query-string";
import SystemService from "../../../services/SystemService";
import _ from "lodash";
import { navigate } from "@reach/router";
import labels from "../../../helpers/labels.json";
import UpdateCaseModal from "../UpdateCaseModal";

async function getInvitedProfiles(query = "") {
  try {
    const response = await SystemService.getInvitedProfiles(query);
    return { ...response };
  } catch (error) {
    return error;
  }
}

const InviteMediatorListing = () => {
  const [state, setState] = useState({});
  const placeholderText = labels.search_mediator;
  const MTRef = useRef();
  const [modal, setModal] = useState();
  const [caseObj, setCaseObj] = useState();

  const columns = [
    {
      field: "id",
      title: labels.case_id,
      tooltip: "Unique Identifier for the Case across JustAct platform",
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData?.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData.id}
        </HyperLink>
      ),
    },
    {
      field: "name",
      title: labels.profile_name,
      render: (rowData) => (
        <Bold>{rowData?.requestedMediator?.name || "-"}</Bold>
      ),
      sorting: false,
    },
    {
      field: "email",
      tooltip: "Email associated with the Mediator",
      title: labels.profile_email,
      sorting: false,
      render: (rowData) => <>{rowData?.requestedMediator?.email || "-"}</>,
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },
    {
      field: "mobile",
      tooltip: "Mobile number associated with the Mediator",
      title: labels.profile_mobile,
      render: (rowData) => <>{rowData?.requestedMediator?.mobile || "-"}</>,
      sorting: false,
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },
    {
      field: "action",
      title: labels.actions.substring(0, labels.actions.length - 1),
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() => {
            setCaseObj(rowData);
            setModal(true);
          }}
        >
          {labels.edit_mediator}
        </HyperLink>
      ),
      tooltip: "Option to edit Mediator profile for the corresponding case",
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      if (query.search) {
        params.query = query.search;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getInvitedProfiles(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <div className="myProfiles">
      <Padding>
        <CustomTable
          hideFilterBar
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={_.startCase(labels.mediator)}
          singularTitle=""
          onFilterClick={() => setModal(true)}
          {...{
            columns,
            data,
            placeholderText,
            MTRef,
          }}
          // noToolbar
          // hideFilterBar
          state={state}
        />
        <UpdateCaseModal
          {...{ modal, setModal, caseObj }}
          onSuccess={() => MTRef.current.onQueryChange()}
        />
      </Padding>
    </div>
  );
};

export default InviteMediatorListing;

const Padding = styled.div`
  // padding-top: 30px;
  padding-bottom: 50px;
`;
const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
