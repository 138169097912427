import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { ButtonContainer } from "../../pages/ProfileDetails/styles";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { Formik } from "formik";
import { UpdateCaseSchema } from "../../../helpers/validationSchema";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import FormField from "../../common/FormField/FormField";
import CaseService from "../../../services/CaseService";

const inputs = [
  {
    name: "name",
    label: labels["profile_name"],
    type: "text",
    required: true,
  },
  {
    name: "email",
    label: labels["profile_email"],
    type: "text",
    required: true,
  },
  {
    name: "phone",
    label: labels["profile_mobile"],
    type: "text",
    required: true,
  },
];

export default function UpdateCaseModal({
  modal,
  setModal,
  caseObj,
  onSuccess,
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    const postData = {
      requestedMediator: {
        ...values,
        mobile: values?.phone,
      },
    };
    try {
      setLoader({ state: true, message: "Updating profile..." });
      const response = await CaseService.updateCase(caseObj?.id, postData);
      if (response?.message) {
        onSuccess();
        setModal(false);
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Drawer anchor={"right"} open={modal}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{labels.edit_mediator}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Formik
            initialValues={{
              name: caseObj?.requestedMediator?.name || "",
              email: caseObj?.requestedMediator?.email || "",
              phone: caseObj?.requestedMediator?.mobile || "",
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={UpdateCaseSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              isValid,
            }) => (
              <>
                <Flex style={{ flexWrap: "wrap" }}>
                  {inputs?.map((input, index) => (
                    <FormField
                      key={index}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                      handleChange={(e) => {
                        if (input.name === "phone") {
                          if (e?.target?.value?.match(/^[0-9]*$/)) {
                            setFieldValue(input?.name, e?.target?.value);
                          }
                        } else {
                          setFieldValue(input?.name, e?.target?.value);
                        }
                      }}
                    />
                  ))}
                </Flex>
                <ButtonContainer>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    disabled={!isValid}
                    style={{ width: 413, margin: "0 auto" }}
                  >
                    {labels.update_mediator}
                  </PrimaryCTAButton>
                </ButtonContainer>
              </>
            )}
          </Formik>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 618px;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
