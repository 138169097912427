import React, { useState } from "react";
import theme from "../../../../assets/theme";
import { Checkbox } from "@material-ui/core";
import { Images } from "../../../../assets/images";
import styled from "styled-components";
import { Formik, FieldArray } from "formik";
import DescriptionModal from "../../../common/DescriptionModal/DescriptionModal";

const ClaimTabel = ({
  items,
  enableCheckBox,
  selectAll,
  itemsChecked,
  disableChekBox,
  heading,
}) => {
  const [modal, setModal] = useState({
    state: false,
  });

  return (
    <Formik
      initialValues={{
        items: items,
      }}
      enableReinitialize
    >
      {({ values, handleChange }) => (
        <>
          <ClaimBoxHeading>{heading}</ClaimBoxHeading>
          <ClaimTable>
            <tbody>
              <TR>
                {enableCheckBox && (
                  <TH>
                    <Checkbox
                      icon={<img alt="checked" src={Images.checkBox} />}
                      checked={items?.every((el) =>
                        el?.offer
                          ? el?.offer?.status === "accepted"
                          : el?.acceptedOffer?.status === "accepted"
                      )}
                      onChange={(ev) => selectAll(ev)}
                      disabled={disableChekBox}
                      checkedIcon={
                        <img alt="checked" src={Images.checkBoxSelected} />
                      }
                    />
                  </TH>
                )}
                <TH>Claim Item</TH>
                <TH>Claim Value</TH>
                <TH>Unit</TH>
                <TH>Supporting Document</TH>
                <TH>Claim Description</TH>
              </TR>
              {values?.items && (
                <FieldArray
                  name="items"
                  render={() =>
                    values?.items?.map((item, index) => (
                      <TR
                        isEven={index % 2 === 0}
                        key={index}
                        accepted={
                          item?.offer
                            ? item?.offer?.status === "accepted"
                            : item?.acceptedOffer?.status === "accepted"
                        }
                      >
                        {enableCheckBox && (
                          <TD>
                            <Checkbox
                              icon={<img alt="checked" src={Images.checkBox} />}
                              checked={
                                item?.offer
                                  ? item?.offer?.status === "accepted"
                                  : item?.acceptedOffer?.status === "accepted"
                              }
                              onChange={(ev) => itemsChecked(ev, item)}
                              disabled={
                                disableChekBox ||
                                item?.acceptedOffer?.status === "accepted"
                              }
                              checkedIcon={
                                <img
                                  alt="checked"
                                  src={Images.checkBoxSelected}
                                />
                              }
                            />
                          </TD>
                        )}
                        <TD>
                          <div className="element">{item.title}</div>
                        </TD>
                        <TD className="claim-table">
                          <div className="element">
                            {item?.offer
                              ? item?.offer?.claimValue
                              : item?.acceptedOffer
                              ? item?.acceptedOffer?.claimValue
                              : item.claimValue}
                          </div>
                        </TD>
                        <TD>
                          <div className="element">{item.unit}</div>
                        </TD>
                        <TD>
                          {item.description_document_url?.url ? (
                            <HyperLink
                              onClick={() =>
                                window.open(item.description_document_url?.url)
                              }
                            >
                              {"Download"}
                            </HyperLink>
                          ) : (
                            <div>-</div>
                          )}
                        </TD>
                        <TD>
                          {item.description || item?.offer?.reason ? (
                            <HyperLink
                              onClick={() =>
                                setModal({
                                  state: true,
                                  name: `items[${index}].description`,
                                  index,
                                  des: item?.offer?.reason || item.description,
                                })
                              }
                            >
                              View
                            </HyperLink>
                          ) : (
                            <div>-</div>
                          )}
                        </TD>
                      </TR>
                    ))
                  }
                />
              )}
            </tbody>
          </ClaimTable>
          <DescriptionModal
            {...{
              modal,
              setModal,
              handleChange,
              values,
              readOnly: true,
            }}
          />
        </>
      )}
    </Formik>
  );
};

export default ClaimTabel;

const ClaimTable = styled.table`
  width: 85%;
  border-radius: 7px;
  background-color: #f9f9f9;
  & .tool-tip {
    border: solid 0.5px #007aff;
    & .name {
      font-family: ${theme.fonts.primaryFontBold};
      font-size: 12px;
      font-weight: bold;
      line-height: 1.83;
      color: #007aff;
    }
    & .message {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 12px;
      font-weight: 600;
      color: #293461;
    }
  }
  .input-white {
    font-size: 12px;
    padding: 8px;
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

const TR = styled.tr`
  .element {
    opacity: ${(props) => (props.accepted ? "0.3" : "1")};
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 12px;
    color: #293461;
  }
`;

const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;

const TH = styled.th`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  color: #949ab0;
  text-align: left;
  padding: 13px 21px;
  text-transform: uppercase;
  & span {
    padding: 0px;
  }
`;

const TD = styled.td`
  padding: 10px 21px;
  & span {
    padding: 0px;
  }
`;

const ClaimBoxHeading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  color: #293461;
  text-transform: uppercase;
  padding-bottom: 16px;
  &:not(:first-of-type) {
    padding-top: 20px;
  }
`;
