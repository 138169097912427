import theme from "../../assets/theme";
import React from "react";
import {
  withStyles,
  TextField,
  MenuItem,
  Checkbox,
  makeStyles,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import COLORS from "../../assets/Colors";
import styled, { css } from "styled-components";
import MuiPhoneNumber from "material-ui-phone-number";

export const StyledInput = {
  root: {
    width: "100%",
    fontSize: "16px",
    color: `${COLORS.COLOR_DARK} !important`,
    fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    // background: COLORS.INPUT_BACKGROUND,
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.BTN_GREEN,
    },
    "& label": {
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& label.Mui-focused": {
      color: COLORS.COLOR_DARK,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "16px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.INPUT_BACKGROUND,
      height: 50,
      "& fieldset": {
        borderRadius: "6px",
        border: `1px solid ${COLORS.INPUT_BORDER}`,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.INPUT_BORDER,
      },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiSelect-outlined": {
      color: COLORS.COLOR_DARK,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
    " & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
};

const StyledMenuItem = withStyles({
  root: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  selected: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
})(MenuItem);

export const CustomInputField = withStyles(StyledInput)(TextField);
export const CustomMobileInput = withStyles(StyledInput)(MuiPhoneNumber);
export const CustomDatePicker = withStyles(StyledInput)(DatePicker);

export { StyledMenuItem };

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: `22px !important`,
    },
  },
  checked: {
    color: `${COLORS.COLOR_DARK} !important`,
  },
};

export const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN,
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 42,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN,
  },
}));

export function CustomFileUpload({
  width,
  file = {},
  setFile = () => {},
  accept = ".pdf",
  dark = false,
  ...props
}) {
  const classes = useStyles();
  const setFileName = (file) => {
    if (file) {
      const fileName = decodeURI(file);
      return fileName.length < 30
        ? fileName
        : fileName.substring(0, 30) + "...";
    } else {
      return "Select a file";
    }
  };
  return (
    <FileContainer {...{ width, dark }} {...props}>
      <FileName>{setFileName(file?.name)}</FileName>
      <Button
        disableElevation
        className={classes.button}
        variant="contained"
        component="label"
      >
        Browse
        <input
          onChange={(e) => e?.target?.files && setFile(e.target.files[0])}
          type="file"
          style={{ display: "none" }}
          accept={accept}
        />
      </Button>
      {props.error && <ErrorText>{props.error}</ErrorText>}
    </FileContainer>
  );
}

const ErrorText = styled.span`
  position: absolute;
  font-size: 10px;
  color: #f44336;
  font-family: openSans-SemiBold, sans-serif;
  bottom: -16px;
  left: 3px;
`;

export function CustomRadioGroup({
  values = {},
  name = "",
  handleChange = () => {},
  radioInputs = [],
  direction = "row",
  checkedIcon = null,
  handleFocus = () => {},
}) {
  const classes = useStyles();
  return (
    <RadioGroup
      className={classes.radioGroup}
      style={{ flexDirection: direction }}
      aria-label={name}
      name={name}
      value={values[name]}
      onChange={handleChange}
      onFocus={() => handleFocus(values[name])}
    >
      {radioInputs.map((radio) => (
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value={radio.value}
          disabled={radio.disabled}
          control={
            <Radio
              color="primary"
              autoFocus={radio.autofocus}
              icon={
                <img
                  src={require("../../assets/images/checkbox.svg")}
                  alt="check"
                />
              }
              checkedIcon={
                <CheckedIconContainer>
                  <CheckIconFiller />
                </CheckedIconContainer>
              }
              classes={{
                colorPrimary: classes.radioButton,
              }}
            />
          }
          label={radio.label}
        />
      ))}
    </RadioGroup>
  );
}

const FileContainer = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + "px" : "436px")};
  background-color: ${({ dark }) =>
    dark ? COLORS.INPUT_BACKGROUND : COLORS.PRIMARY_WHITE};
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 12px;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;

const FileName = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  letter-spacing: 0.1px;
  color: ${COLORS.COLOR_DARK};
`;

const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.BTN_GREEN};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIconFiller = styled.div`
  width: 14px;
  height: 14px;
  background: ${COLORS.BTN_GREEN};
  border-radius: 50%;
`;

export const CustomCheckboxClosed = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img 
        src={require("../../assets/images/checkbox.svg")} 
        alt="check" 
      />
    }
    checkedIcon={
      <img
        src={require("../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

export const CustomCheckbox = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={<img src={require("../../assets/images/checkbox.svg")} alt="check" />}
    checkedIcon={
      <img
        src={require("../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

export const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
