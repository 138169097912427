import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";
import { useState } from "react";
import useLoader from "../../../../hooks/useLoader";

import { CustomCheckbox, CheckBoxLabel, CheckboxContainer } from "../../../common/FormInputs";


export default function CaseManager({
    id,
    status,
    noToolbar,
    isActionsDisabled = false,
    caseDetails,
    selection = false,
    ...props
}) {
    const [meetingDetail, setMeetingDetail] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const { setLoader } = useLoader();
    const [checked, setChecked] = useState();
    const MTRef = useRef();

    const toggleDarkMode = () => {
        console.log(checked);
        setChecked(!checked);
      };

    useEffect(() => {
        if (id) {
          getCaseDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    async function getCaseDetails(id) {
        try {
            const meetingurl = await CaseService.GetMeetingcases(id);
            setMeetingDetail(meetingurl);
            console.log("okayyyy", meetingurl);
            return
        } catch (error) {
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          }
    }

    const enableOrDisable = async ({ status }) => {
        try {
          const meetingStatus = status === 'closed' ? "open" : "closed";
          setLoader({ state: true, message: "Updating Status..." });
          const response = await CaseService.DisableEnableMeeting(id, meetingStatus);
          //console.log(response);
          if (response) {
              console.log(response);
            if (response) {
              enqueueSnackbar(response.message, {
                variant: "success",
              });
              getCaseDetails(id);
            }
          }
        } catch (error) {
          const message = getErrorMessage(error);
          console.log(message);
          enqueueSnackbar(message, {
            variant: "error",
          });
        } finally {
          setLoader({ state: false });
          // setOpen(false);
        }
      };


    return (
        <CheckboxContainer>
            {meetingDetail?.status ? 
              <CheckboxContainer>
                <CustomCheckbox
                      id="secret"
                      inputRef={MTRef}
                      onChange={() => toggleDarkMode(enableOrDisable(meetingDetail))}
                      checked={meetingDetail?.status === "open"}
                    >
                </CustomCheckbox>
                <CheckBoxLabel>
                  Enable VC link
                </CheckBoxLabel>
              </CheckboxContainer> 
            : ""   
        } 
        </CheckboxContainer>
    );
}
