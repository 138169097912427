export const states = [
  { label: "Andaman and Nicobar", value: "AN", pinStart: [74] },
  { label: "Andhra Pradesh", value: "AP", pinStart: [50, 51, 52, 53] },
  { label: "Arunachal Pradesh", value: "AR", pinStart: [79] },
  { label: "Assam", value: "AS", pinStart: [78] },
  { label: "Bihar", value: "BR", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Chandigarh", value: "CG", pinStart: [16] },
  { label: "Chhattisgarh", value: "CH", pinStart: [45, 46, 47, 48, 49] },
  { label: "Dadra and Nagar Haveli", value: "DH", pinStart: [39] },
  { label: "Daman and Diu", value: "DD", pinStart: [36, 39] },
  { label: "Delhi", value: "DL", pinStart: [11] },
  { label: "Goa", value: "GA", pinStart: [40] },
  { label: "Gujarat", value: "GJ", pinStart: [36, 37, 38, 39] },
  { label: "Haryana", value: "HR", pinStart: [13] },
  { label: "Himachal Pradesh", value: "HP", pinStart: [17] },
  { label: "Jammu and Kashmir", value: "JK", pinStart: [18, 19] },
  { label: "Jharkhand", value: "JH", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Karnataka", value: "KA", pinStart: [56, 57, 58, 59] },
  { label: "Kerala", value: "KL", pinStart: [67, 68, 69] },
  { label: "Ladakh", value: "LA", pinStart: [18, 19] },
  { label: "Lakshadweep", value: "LD", pinStart: [68] },
  { label: "Madhya Pradesh", value: "MP", pinStart: [45, 46, 47, 48, 49] },
  { label: "Maharashtra", value: "MH", pinStart: [40, 41, 42, 43, 44] },
  { label: "Manipur", value: "MN", pinStart: [79] },
  { label: "Meghalaya", value: "ML", pinStart: [79] },
  { label: "Mizoram", value: "MZ", pinStart: [79] },
  { label: "Nagaland", value: "NL", pinStart: [79] },
  { label: "Odisha", value: "OR", pinStart: [75, 76, 77] },
  { label: "Puducherry", value: "PY", pinStart: [60] },
  { label: "Punjab", value: "PB", pinStart: [14, 15, 16] },
  { label: "Rajasthan", value: "RJ", pinStart: [30, 31, 32, 33, 34] },
  { label: "Sikkim", value: "SK", pinStart: [73] },
  { label: "Tamil Nadu", value: "TN", pinStart: [60, 61, 62, 63, 64] },
  { label: "Telangana", value: "TS", pinStart: [50, 51, 52, 53] },
  { label: "Tripura", value: "TR", pinStart: [79] },
  {
    label: "Uttar Pradesh",
    value: "UP",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "Uttarakhand",
    value: "UK",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "West Bengal",
    value: "WB",
    pinStart: [70, 71, 72, 73, 74],
  },
];

/**
 * This the Case Life cycle for CLaimant
 */
export const AWAITING_RESPONDANT = "awaitingRespondant";
export const RESPONDANT_ONBOARDED = "respondantOnboarded";
export const NEGOTIATION_ONGOING = "negotiationOngoing";
export const NEGOTIATION_REACHED = "negotiationReached";
export const NEGOTIATION_QUIT_BY_CLAIMENT = "quitByClaimant";
export const NEGOTIATION_QUIT_BY_RESPONDENT = "quitByRespondent";
export const NEGOTIATION_QUIT_BY_CASEMANAGER = "closedByCaseManager";
export const CLAIMENT_PAID_NEGOTIATION = "claimantPaidNegotiationSuccessFee";
export const RESPONDENT_PAID_NEGOTIATION =
  "respondentPaidNegotiationSuccessFee";
export const BOTH_PAID_NEGOTIATION = "bothPaidNegotiationSuccessFee";

export const CLAIMENT_STR = "claimant";

/**This variable is use for CLaimant life cycle */
export const CLAIMANT_LIFE_CYCLE = [
  {
    label: "Awaiting Respondent onboarding",
    key: AWAITING_RESPONDANT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Case Accepted/Waiting",
    key: RESPONDANT_ONBOARDED,
    index: 2,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false
      }
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false
      }
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 3,
    enableSelection: false,
    cta: [
      {
        type: "REOPEN_CASE",
        label: "Reopen Case",
        displayBtn: true,
        danger: false
      }
    ],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_ONGOING,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Concluded",
    key: NEGOTIATION_REACHED,
    index: 4,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: BOTH_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
];

/**
 * This is the Case Life cycle for Respontent
 */
export const RESPONTANT_LIFE_CYCLE = [
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_ONGOING,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Concluded",
    key: NEGOTIATION_REACHED,
    index: 2,
    enableSelection: false,
    cta: [
      {
        type: "MOVE_TO_AGREEMENT",
        label: "Go to Agreement",
        displayBtn: true,
      },
    ],
  },
  {
    label: "Agreement",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: BOTH_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 3,
    enableSelection: false,
    cta: [],
  },
];

export const SplitAudioMinutes = [
  { label: "Select Duration", value: "", disabled: true },
  {
    label: "15 Minutes",
    value: "15"
  },
  { label: "30 Minutes", value: "30" },
  { label: "45 Minutes", value: "45" },
  { label: "60 Minutes", value: "60" }
];