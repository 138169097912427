import React, { forwardRef, Fragment } from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper, TablePagination } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import TableToolbar from "./common/TableToolbar";
import CustomPagination from "./common/CustomPagination";
import TableEmptyBlock from "./common/TableEmptyBlock";
import CustomHeader from "./common/CustomHeader";
import theme from "../../../assets/theme";
import { ChevronRight } from "@material-ui/icons";

export default function CustomTable({
  hideSearch = false,
  hidePagination = false,
  hideFilterBar = false,
  filters,
  selectedFilter,
  setSelectedFilter,
  placeholderText,
  pageSize = 5,
  MTRef = {},
  state = {},
  noToolbar,
  pluralTitle = "Records",
  singularTitle = "record",
  customFilter,
  filterCount = 0,
  selection = false,
  onFilterClick = () => {},
  onSelectionChange = () => {},
  customMessage,
  ...props
}) {
  const tableIcons = {
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight
        {...props}
        ref={ref}
        style={{
          height: 16,
          width: 16,
          color: "#00838c",
        }}
      />
    )),
  };

  return (
    <div className="custom-table">
      {noToolbar ? null : (
        <TableToolbar
          {...{
            filters,
            MTRef,
            selectedFilter,
            setSelectedFilter,
            placeholderText,
            hideFilterBar,
            hideSearch,
            customFilter,
            filterCount,
            onFilterClick,
          }}
        />
      )}
      <MaterialTable
        icons={tableIcons}
        tableRef={MTRef}
        localization={{
          pagination: {
            labelRowsPerPage: "",
          },
        }}
        detailPanel={props.detailPanel}
        options={{
          pageSize,
          detailPanelType: "multiple",
          detailPanelColumnAlignment: "right",
          pageSizeOptions: [pageSize],
          emptyRowsWhenPaging: state?.data?.length === 0,
          showEmptyDataSourceMessage: false,
          selection,
          selectionProps: {
            checkedIcon: (
              <img
                alt="checked"
                src={require("../../../assets/images/checkBoxSelected.svg")}
              />
            ),
            icon: (
              <img
                alt="checked"
                src={require("../../../assets/images/checkbox.svg")}
              />
            ),
            disableRipple: true,
          },
          showSelectAllCheckbox: selection,
        }}
        components={{
          Container: (props) => (
            <Paper
              {...props}
              style={{ backgroundColor: "transparent" }}
              elevation={0}
            />
          ),
          Cell: (props) => {
            return <StyledCell {...props} />;
          },
          Pagination: (props) => (
            <TablePagination
              classes={{ root: "default_pagination" }}
              {...props}
            />
          ),
          Toolbar: () => <Fragment />,
          Header: (props) => <CustomHeader {...props} MTRef={MTRef} />,
        }}
        onSelectionChange={onSelectionChange}
        {...props}
      />
      {state?.data?.length === 0 && (
        <TableEmptyBlock
          customMessage={customMessage}
          pluralTitle={pluralTitle}
          singular={singularTitle}
        />
      )}
      {!hidePagination && <CustomPagination {...{ MTRef, state }} />}
    </div>
  );
}

const StyledCell = styled(MTableCell)`
  border-bottom: 0;
  font-family: ${theme.fonts.primaryFontRegular} !important;
  color: ${COLORS.COLOR_DARK} !important;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
