import React, { useState, useRef, Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import { navigate } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useEffect } from "react";
import {
  numberFormat,
  getCaseLabelByStatus,
  getErrorMessage,
  parseTimeStamp,
} from "../../../helpers/functions";
import CaseManagerService from "../../../services/CaseManagerService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import AlertDialog from "../../common/Alert";
import { PrimaryCTAButton } from "../../common/Buttons";
import CaseService from "../../../services/CaseService";

const initFilters = [
  { label: "All", key: "allCount", value: 0 },
  {
    label: "Waiting For Respondent",
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  { label: "Under Resolution", key: "underResolution", value: 0 },
  { label: "Settled", key: "settled", value: 0 },
  { label: "Drafts", key: "draftCount", value: 0 },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Drafts":
      value = "draft";
      break;
    case "Under Resolution":
      value = "underResolution";
      break;
    case "Waiting For Respondent":
      value = "awaitingRespondent";
      break;
    case "Settled":
      value = "settled";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCases(query = "", id, caseType, role) {
  let param;
  if (caseType && id) {
    param = `?resolutionKind=${caseType}&${role}=${id}`;
  } else if (id) {
    param = `?${role}=${id}`;
  } else {
    param = `?resolutionKind=${caseType}`;
  }
  try {
    const response = await CaseManagerService.getCaseListing(query);
    const stats = await CaseManagerService.getCaseStats(param);
    return { ...response, stats };
  } catch (error) {
    return error;
  }
}

const Index = ({ id, caseType, role = "" }) => {
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const placeholderText = "Search Cases";
  const [selectedFilter, setSelectedFilter] = useState();
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (selectedFilter || caseType) {
      MTRef.current.onQueryChange("");
    }
  }, [selectedFilter, caseType]);

  const columns = [
    {
      field: "id",
      title: "Case ID",
      sorting: true,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData.id}
        </HyperLink>
      ),
      tooltip: "Unique Identifier for the Case across JustAct platform",
      headerStyle: {
        paddingLeft: "26px",
      },
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData.title}
        </Bold>
      ),
      sorting: false,
    },
    {
      field: "party",
      title: "Party",
      tooltip: "Parties representing to file the case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData?.claimantParty?.name}
        </div>
      ),
      //render: (rowData) => <>{rowData?.claimantParty?.name}</>,
      sorting: false,
    },
    {
      field: "respondentName",
      title: "Counterparty",
      sorting: false,
      tooltip: "Party countering your case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </div>
      ),
      // render: (rowData) => (
      //   <>
      //     {rowData?.respondentParty?.name ? (
      //       rowData.respondentParty.name
      //     ) : rowData?.respondentName ? (
      //       rowData.respondentName
      //     ) : (
      //       <div style={{ marginLeft: 35 }}>-</div>
      //     )}
      //   </>
      // ),
    },
    {
      field: "totalClaimValue",
      title: "Claim Value",
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const divStyle = {
          cursor: "pointer",
        };
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(
                  `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
                )
              }
            >
              {numberFormat(parseFloat(caseItems).toFixed(2), "INR")}
            </div>
          );
        else
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(
                  `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
                )
              }
            >
              {"Non Monetary"}
            </div>
          );
      },
      // render: (rowData) => {
      //   const caseItems = rowData.totalClaimValue;
      //   if (caseItems)
      //     return (
      //       "INR " +
      //       formatNumberToMetrics(parseFloat(caseItems).toFixed(2), "INR")
      //     );
      //   else return "Non Monetary";
      // },
      sorting: true,
    },
    {
      field: "status",
      title: "Status",
      tooltip: "Status of the Case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          {getCaseLabelByStatus(rowData)}
        </div>
      ),
      //render: (rowData) => <>{getCaseLabelByStatus(rowData)}</>,
    },
    {
      field: "submittedOn",
      title: "Created On",
      tooltip: "Case Created Date",
      headerStyle: {
        width: 120,
      },
      render: (rowData) => (
        <Datecolumn>
          <div
            className="date"
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
              )
            }
          >
            {rowData?.submittedOn
              ? moment(rowData.submittedOn).format("DD/MM/YYYY")
              : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          {rowData?.status === "draft" && (
            <div
              className="delete"
              onClick={() => deleteWaring(false, rowData?.id)}
            >
              {"Delete"}
            </div>
          )}
        </Datecolumn>
      ),
      // render: (rowData) => (
      //   <>
      //     {moment(
      //       rowData.submittedOn ? rowData.submittedOn : rowData.created_at,
      //       "YYYY-MM-DD HH:mm:ss"
      //     ).format("DD/MM/YYYY")}
      //   </>
      // ),
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      params.selected = true;
      if (id) {
        params[role] = id;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      if (caseType) {
        params.resolutionKind = caseType;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams, id, caseType, role)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result?.data?.map((row) => ({
                ...row,
                tableData: { checked: row?.isSelected },
              })),
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleCaseSelection = async (cases, data) => {
    const param = `?resolutionKind=${caseType}`;
    if (cases?.length > 1 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.addAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else if (cases?.length === 0 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.removeAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        if (data?.isSelected) {
          await CaseService.removeCaseItem(data.id);
        } else {
          await CaseService.selectCaseItem(data?.id);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    }
  };

  const deleteWaring = (isSelectAll, id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () =>
        isSelectAll ? deleteSelectedDraft() : deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case(s)?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        margin: "0px 60px 50px",
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  /**
   * @description Function to deleted the selected case
   */
  const deleteSelectedDraft = async () => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteSelectedCases();
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle="Cases"
          selection={selectedFilter === "Drafts" && !role}
          onSelectionChange={handleCaseSelection}
          singularTitle=""
          {...{
            columns,
            data,
            filters,
            selectedFilter,
            setSelectedFilter,
            placeholderText,
            MTRef,
          }}
          state={state}
        />
        <Footer>
          <div className="flex mt20">
            <div
              style={{
                display:
                  selectedFilter === "Drafts" &&
                  state?.data?.some((el) => el.isSelected)
                    ? "flex"
                    : "none",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <DangerBtn
                style={{
                  width: "216px",
                  marginLeft: "26px",
                }}
                onClick={() => deleteWaring(true)}
              >
                {"Delete"}
              </DangerBtn>
            </div>
          </div>
        </Footer>
        <AlertDialog isOpen={open} {...{ ...dialogData }} />
      </Padding>
    </Fragment>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Datecolumn = styled.div`
  .date {
    cursor: pointer;
  }
  .delete {
    margin-top: 2px;
    cursor: pointer;
    color: ${COLORS.LOGOUT_RED};
    font-size: 12px;
  }
`;

export const Footer = styled.footer`
  & .highlighted {
    color: red;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

export const DangerBtn = styled(PrimaryCTAButton)`
  background-color: #ff3c3c;
  &:focus,
  &:hover {
    background-color: #ff4d4d;
    border: 1px solid #ff3c3c;
  }
  border: 1px solid #ff3c3c;
`;
