import API from "../api/manager";
import config from "../api/config";

const negotiations = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}/negotiations`,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};

const getCase = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}`,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url:
      config.urls.case.getCaseDocuments +
      `/case/${id}/dealAgreement` +
      `${query}`,
  });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const assignCaseManager = (data, managerId) => {
  return API({
    method: "PUT",
    url: config.urls.case.assignCase + `/${data?.id}/${managerId}`,
    data: {},
  });
};

const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const deleteDraftCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.draft + `/${id}`,
    data: {},
  });
};

const deleteSelectedCases = () => {
  return API({
    method: "DELETE",
    url: config.urls.case.selectDraft,
    data: {},
  });
};

const addAllCases = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.select + query,
    data: {},
  });
};

const removeAllCases = (query) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + query,
    data: {},
  });
};

const selectCaseItem = (id) => {
  return API({
    method: "POST",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const removeCaseItem = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const RecordingControl = (id, recordingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.recordControl + `/${id}/${recordingStatus}`,
  });
};

const GetMeetingcases = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.mettingcase + `/${id}`,
  });
};

const getRoom = (query) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetingDet + query,
  })
}

const DisableEnableMeeting = (id, meetingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.meetingaccessadmin + `/${id}/${meetingStatus}`,
  });
};

const updateCase = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${caseId}`,
    data,
  });
};

const getOrders = (query) => {
  return API({ method: "GET", url: config.urls.cart.orders + query });
};

const getRefundHistory = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const publishMediator = (data) => {
  return API({
    method: "PUT",
    url: config.urls.case.publishMediator,
    data,
  });
};

const addTranscript = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.transcripts}`,
    data,
  });
};

const updateTranscript = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.transcripts + `/${id}`,
    data,
  });
};

const deleteTranscriptDoc = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.transcripts + `/${id}`,
    data: {},
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const getSplitAudio = (query, id) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getAudioFile}/${id}${query}`
  });
};

const getAudioDetails = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getMeetingAudioDetails}/${id}${query}`
  });
};

const updateCaseReopen = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.reOpenCase}/${id}`,
    data
  });
};

const joinMeeting = adminId => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingJoin}/${adminId}`
  });
};

const getRecordings = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingRecordings}/${query}`
  });
};

const getMeetingLink = () => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingUrl}`
  });
};

const getGenericMeeting = (data, code) => {
  return API({
    method: "POST",
    url: `${config.urls.meeting.genericMeeting}/${code}`,
    data,
  }, false);
};

export default {
  negotiations,
  getCase,
  getRoom,
  getOrders,
  getRefundHistory,
  getcaseDocuments,
  caseAddDocument,
  assignCaseManager,
  mediations,
  sendComment,
  deleteDraftCase,
  getMeeting,
  RecordingControl,
  GetMeetingcases,
  DisableEnableMeeting,
  deleteSelectedCases,
  addAllCases,
  removeAllCases,
  selectCaseItem,
  removeCaseItem,
  getPartyListByCaseId,
  updateCase,
  downloadCaseAgreement,
  publishMediator,
  addTranscript,
  updateTranscript, 
  deleteTranscriptDoc,
  getMeetingVideo,
  getSplitAudio,
  getAudioDetails,
  updateCaseReopen,
  joinMeeting,
  getRecordings,
  getMeetingLink,
  getGenericMeeting,
};
