import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import labels from "../../../helpers/labels.json";
import CaseManagerService from "../../../services/CaseManagerService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { Container } from "../../../styles/component/style";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import styled, { css } from "styled-components";
import {
  PrimaryOutlinedCTAButton,
  SecondaryOutlinedCTAButton,
} from "../../common/Buttons";
import CasesTable from "../../common/CasesTable";
import { navigate } from "@reach/router";
import AlertDialog from "../../common/Alert";

const ViewCaseManager = ({ id }) => {
  const [managerDetail, setManagerDetail] = useState();
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "case_managers":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (id) {
      getCaseManagerDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function getCaseManagerDetails(id) {
    setLoader({ state: true, message: "Fetching case details..." });
    const res = await CaseManagerService.getCMDetails(id);
    try {
      if (res?.caseManager) {
        setManagerDetail(res.caseManager);
        const breadcrumbs = [
          _.startCase(labels.case_manager),
          res.caseManager.name,
        ];
        setBreadcrumbs(breadcrumbs);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const enableOrDisable = async ({ disabled }) => {
    try {
      const status = disabled ? "enable" : "disable";
      setLoader({ state: true, message: "Updating..." });
      const response = await CaseManagerService.deleteCaseManager(id, status);
      if (response) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          getCaseManagerDetails(id);
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <Row
          style={{
            width: 900,
            justifyContent: "space-between",
          }}
        >
          <Row
            style={{
              width: 500,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <BasicDetailsLabel>{labels["profile_name"]}</BasicDetailsLabel>
              <BasicDetailsValue>{managerDetail?.name}</BasicDetailsValue>
            </div>
            <div>
              <BasicDetailsLabel>{labels["profile_email"]}</BasicDetailsLabel>
              <BasicDetailsValue>{managerDetail?.email}</BasicDetailsValue>
            </div>
            <div>
              <BasicDetailsLabel>{labels["profile_mobile"]}</BasicDetailsLabel>
              <BasicDetailsValue>
                {managerDetail?.mobile ? managerDetail?.mobile : "-"}
              </BasicDetailsValue>
            </div>
          </Row>
          <Row>
            <PrimaryOutlinedCTAButton
              className="mr15"
              onClick={() => navigate(`/dashboard/edit/${id}`)}
            >
              Edit
            </PrimaryOutlinedCTAButton>
            {managerDetail?.disabled ? (
              <PrimaryOutlinedCTAButton
                outlined
                className="mr15"
                onClick={() => setOpen(true)}
              >
                Activate
              </PrimaryOutlinedCTAButton>
            ) : (
              <SecondaryOutlinedCTAButton
                className="ml15"
                onClick={() => setOpen(true)}
              >
                Deactivate
              </SecondaryOutlinedCTAButton>
            )}
          </Row>
        </Row>
      </Container>
      <div>
        <CasesTable id={id} role="caseManagerId" />
      </div>

      <div>
        <AlertDialog
          isOpen={open}
          clickSecondarybtn={() => setOpen(false)}
          primaryBtnText={"Okay"}
          secondaryBtnText={"Cancel"}
          desc={`Are you sure? You want to ${
            managerDetail?.disabled ? "enable" : "disable"
          } this Case manager`}
          heading={`${
            managerDetail?.disabled ? "Enable" : "Disable"
          } Case manager`}
          clickPrimaryBtn={() => enableOrDisable(managerDetail)}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold,
          }}
        />
      </div>
    </Fragment>
  );
};

export default ViewCaseManager;

const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

const BasicDetailsValue = styled.div`
  word-break: break-all;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  & button {
    height: 42px !important;
  }
`;
