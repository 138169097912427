import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { Formik } from "formik";
import { StyledLabel } from "../../../pages/CaseDetails/styles";
import { useSnackbar } from "notistack";
import AWSService from "../../../../services/AWSService";
import useLoader from "../../../../hooks/useLoader";
import labels from "../../../../helpers/labels.json";
import { getErrorMessage, getFileName } from "../../../../helpers/functions";
import { CustomFileUpload } from "../../../common/FormInputs";

export default function UpdateBulkUploadMediation({
  modal,
  setModal,
  systemConfig,
  handleSystemConfigChange = () => {}
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        const types = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv"
        ];
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        const errorMessage = "Invalid File Type";
        if (!types.includes(file?.type)) throw errorMessage;
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Drawer anchor={"right"} open={modal} onClose={() => setModal(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Update Medation Bulk Upload Document</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <EditProfileContainer>
          <Formik
            initialValues={{
              bulkUploadMedTemplateUrl:
                systemConfig?.bulkUploadMedTemplateUrl || ""
            }}
            onSubmit={({ bulkUploadMedTemplateUrl }) =>
              handleSystemConfigChange(
                { ...systemConfig, bulkUploadMedTemplateUrl },
                () => setModal(false)
              )
            }
            validateOnBlur
            enableReinitialize
            validateOnChange
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              setFieldError,
              setFieldValue,
              isValid
            }) => (
              <StyledForm>
                <StyledFormElementsWrapper
                  style={{ margin: "80px 0 74px", paddingLeft: "60px" }}
                >
                  <StyledLabel>
                    {labels.new_mediation_bulK}
                    <span className="required-star"> *</span>
                  </StyledLabel>
                  <CustomFileUpload
                    key={`${values.bulkUploadMedTemplateUrl}`}
                    error={
                      touched.bulkUploadMedTemplateUrl &&
                      errors.bulkUploadMedTemplateUrl
                    }
                    file={{
                      name: getFileName(values?.bulkUploadMedTemplateUrl)
                    }}
                    setFile={f =>
                      s3Upload(
                        f,
                        "bulkUploadMedTemplateUrl",
                        setFieldValue,
                        setFieldError
                      )
                    }
                    accept=".xlsx, .xls, .csv"
                  />
                </StyledFormElementsWrapper>

                <ButtonContainer style={{ marginBottom: 13 }}>
                  <PrimaryCTAButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isValid || !values.bulkUploadMedTemplateUrl}
                    style={{ width: 413, margin: "auto" }}
                  >
                    Update Document
                  </PrimaryCTAButton>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </EditProfileContainer>
      </DrawerContainer>
    </Drawer>
  );
}
const DrawerContainer = styled.div`
  width: 618px;
`;
const HeadingContainer = styled.div`
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;
const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;
const EditProfileContainer = styled.div`
  margin: 0 30px 0 30px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
