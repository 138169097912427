import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";
import { useState } from "react";
import useLoader from "../../../../hooks/useLoader";
import { CustomCheckboxClosed, CheckBoxLabel, CheckboxContainer } from "../../../common/FormInputs";


export default function CaseManager({
    id,
    status,
    created_at,
    noToolbar,
    isActionsDisabled = false,
    caseDetails,
    selection = false,
    ...props
}) {
    const [meetingDetail, setMeetingDetail] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const { setLoader } = useLoader();
    const [checked, setChecked] = useState(sessionStorage.getItem(id) === 'true');
    const MTRef = useRef();
    
    // useEffect(() => {
    //     window.sessionStorage.setItem(id, checked)
    //   }, [checked])
  
      const toggleDarkMode = () => {
          console.log(checked);
          setChecked(!checked);
        };

    useEffect(() => {
        if (id) {
          getCaseDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    async function getCaseDetails(id, toChange = true ) {
        try {
            const meetingurl = await CaseService.GetMeetingcases(id);
            setMeetingDetail(meetingurl);
            console.log(meetingurl);
            return
        } catch (error) {
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          }
    }

    const enableOrDisableRecording = async ({ recordingStatus }) => {
        try {
          const status = recordingStatus === "no" ? "yes" : "no";
          setLoader({ state: true, message: "Updating Status..." });
          const response = await CaseService.RecordingControl(id, status);
          //console.log(response);
          if (response) {
              console.log(response);
            if (response) {
              enqueueSnackbar(response.message, {
                variant: "success",
              });
              getCaseDetails(id);
            }
          }
        } catch (error) {
          const message = getErrorMessage(error);
          console.log(message);
          enqueueSnackbar(message, {
            variant: "error",
          });
        } finally {
          setLoader({ state: false });
          // setOpen(false);
        }
      };


    return (
        <CheckboxContainer>
            {meetingDetail?.recordingStatus ? 
                <CheckboxContainer>
                <CustomCheckboxClosed
                      id="secret"
                      inputRef={MTRef}
                      onChange={() => toggleDarkMode(enableOrDisableRecording(meetingDetail))}
                      checked={meetingDetail?.recordingStatus === "yes"}
                    >
                </CustomCheckboxClosed>
                <CheckBoxLabel>
                  Enable Recording
                </CheckBoxLabel>
              </CheckboxContainer> 
            : ""   
        } 
        </CheckboxContainer>
    );
}
