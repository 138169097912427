import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core";
import AudioSplit from "./AudioSplit";
import AudioRecordings from "./AudioRecordings";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important"
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function TableWithAccordion({ id, meetingStatus, meetingRecordingStatus }) {
  const classes = useStyles();
  const [openAudioModal, setOpenAudioModal] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(e => !e)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading onClick={() => setExpanded(e => !e)}>
            {"Audio Recordings"}
          </AccordionHeading>
          <Actions>
            <HyperLink onClick={() => setOpenAudioModal(true)}>
              {meetingStatus && meetingRecordingStatus === "yes" ? "Split" : ""}
            </HyperLink>
          </Actions>
        </ExpansionPanelSummary>
        <AudioRecordings
          style={{
            paddingLeft: "50px",
            paddingBottom: "10px",
            overflow: "auto"
          }}
          id={id}
        />
      </ExpansionPanel>
      <AudioSplit
        id={id}
        setRefreshDetails={setRefreshDetails}
        refreshDetails={refreshDetails}
        {...{ openAudioModal, setOpenAudioModal }}
      />
    </>
  );
}
export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  margin-bottom: 15px;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const Heading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  flex: 1;
  color: ${COLORS.COLOR_DARK};
  text-transform: capitalize;
`;
