import React from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
} from "../styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DrawerModal from "../DrawerModal/DrawerModal";
import { useState } from "react";

export default function CaseManager({
  caseManager = "Kumar Singh",
  title = "Case Manager",
  addCaseManager,
  disabled = false,
  showRemove = false,
  removeCaseManager = () => {},
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>{title}</CaseManagerTitle>
        <Flex className="flex-1">
          {caseManager?.name ? (
            <>
              <CaseManagerProfile
                src={
                  caseManager?.avatarUrl
                    ? caseManager?.avatarUrl
                    : require("../../../../assets/images/userPlaceholder.svg")
                }
              />
              <CaseManagerName onClick={(e) => setModal(true)}>
                {caseManager?.name}
              </CaseManagerName>
            </>
          ) : (
            <div className="add_case_casemanager flex flex-1">
              Add Case manager
            </div>
          )}
        </Flex>
      </Column>
      {caseManager?.name ? null : (
        <div
          className="add_circle"
          onClick={() => !disabled && addCaseManager()}
        >
          <AddIcon className="add_icon" />
        </div>
      )}
      {showRemove ? (
        <div className="remove" onClick={() => removeCaseManager()}>
          <CloseIcon className="icon" />
        </div>
      ) : null}
      <DrawerModal
        {...{ modal, setModal }}
        id={caseManager?.id}
        type={title}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
