import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import { AudioSplit } from "../../../helpers/validationSchema";
import { SplitAudioMinutes } from "../../../helpers/constants";
import {
  StyledSelectFormControl,
  StyledDropdownIcon
} from "../../common/CustomSelect/styles";
import { StyledMenuItem } from "../../common/FormInputs";
import { Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function AudioSplitModal({
  openAudioModal,
  setOpenAudioModal,
  id,
  setRefreshDetails
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [minutes, setMinutes] = useState();

  useEffect(() => {
    if (openAudioModal) {
      setMinutes("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAudioModal]);

  async function onFormSubmit(values) {
    try {
      setLoader({
        state: true,
        message: `Get ${minutes} Minutes Audio`
      });
      let query = `?audioDuration=${minutes}&audioFileName=${values?.audioFileName}`;
      const response = await CaseService.getSplitAudio(query, id);
      if (response.config) {
        enqueueSnackbar("File is Being process", {
          variant: "success"
        });
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setOpenAudioModal(false);
    }
  }

  const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: COLORS.INPUT_GRAY
    }
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  return (
    <Modal open={openAudioModal}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Split Audio</Heading>
          <CloseModal
            onClick={() => setOpenAudioModal(false)}
            src={require("../../../../src/assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <Formik
          initialValues={{
            audioFileName: ""
          }}
          enableReinitialize
          validationSchema={AudioSplit}
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            errors,
            isValid,
            handleBlur,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <NoteContainer>
                <Flex style={{ flexWrap: "wrap" }}>
                  <LabelSpan>
                    Audio File Name
                    <span className="required-star"> *</span>
                  </LabelSpan>
                  <FormField
                    input={{
                      type: "text",
                      name: "audioFileName",
                      label: null
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue
                    }}
                  />
                </Flex>
                <Label>
                  Select duration of each audio chunk{" "}
                  <span className="required-star"> *</span>
                </Label>
                <StyledSelectFormControl variant="outlined">
                  <Select
                    value={minutes}
                    name={minutes}
                    displayEmpty
                    onChange={e => setMinutes(e.target.value)}
                    renderValue={
                      minutes !== ""
                        ? undefined
                        : () => <Placeholder>Select Duration</Placeholder>
                    }
                    IconComponent={() => (
                      <StyledDropdownIcon
                        alt="select"
                        src={require("../../../assets/images/dropdownIcon.svg")}
                      />
                    )}
                  >
                    {SplitAudioMinutes.map((item, idx) => (
                      <StyledMenuItem
                        key={idx}
                        value={item.value}
                        disabled={item.disabled}
                      >
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </StyledSelectFormControl>
                {/* <Label>
                  Select Duration of each Chunk{" "}
                  <span className="required-star"> *</span>
                </Label>
                <CustomSelect
                  menuItemValues={SplitAudioMinutes}
                  value={minutes}
                  name={minutes}
                  placeholderText="N/A"
                  onChange={e => setMinutes(e.target.value)}
                /> */}
              </NoteContainer>
              <ButtonWrapper
                style={{
                  margin: "auto",
                  width: "418px",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "52px"
                }}
              >
                <PrimaryOutlinedCTAButton
                  style={{
                    height: "48px",
                    width: "162px",
                    marginRight: 20
                  }}
                  onClick={() => setOpenAudioModal(false)}
                >
                  Cancel
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  type={"submit"}
                  disabled={!isValid || !minutes || !values.audioFileName}
                  onClick={handleSubmit}
                  style={{ height: "48px", width: "162px" }}
                >
                  Split
                </PrimaryCTAButton>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
      </DrawerContainer>
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
`;

const LabelSpan = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 2.7;
  color: ${COLORS.COLOR_DARK};
`;

const DrawerContainer = styled.div`
  max-width: 446px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
  & .MuiFormControlLabel-root {
    font-family: openSans-ExtraBold, san-serif;
    & .MuiTypography-body1 {
      font-family: openSans-ExtraBold, san-serif;
    }
  }
  & .sub-heading {
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 16px;
    color: ${COLORS.PRIMARY_BLACK};
  }
  & .history-row {
    display: flex;
    border-bottom: solid 0.5px #acb1c2;
    & .MuiCheckbox-colorPrimary.Mui-disabled {
      cursor: not-allowed !important;
    }
  }
  & .content {
    flex: 1;
    padding: 18px 0px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    font-weight: 600;
    color: ${COLORS.PRIMARY_BLACK};
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
