import React, { Fragment, useEffect, useState, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";
import _ from "lodash";
import { Form, Formik } from "formik";
import { navigate } from "@reach/router";
import { createCaseManager } from "../../../helpers/validationSchema";
import FormField, { HelperText } from "../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../common/Buttons";
import ImageCropper from "../../common/ImageCropper";
import { Container, StyledForm } from "../../../styles/component/style";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
import CaseManagerService from "../../../services/CaseManagerService";
import AWSService from "../../../services/AWSService";
import { dataURLtoFile, getErrorMessage } from "../../../helpers/functions";
import {
  ProfilePic,
  ProfilePicContainer,
} from "../../pages/ProfileDetails/styles";
import { useSnackbar } from "notistack";
import { FormLabel } from "@material-ui/core";
import { CustomCheckBox } from "../../common/FormInputs";

const CreateCaseManager = ({ id }) => {
  const [managerDatail, setManagerDetail] = useState();
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumbs = [
    _.startCase(labels.case_manager),
    _.startCase(id ? labels.edit_case_manager : labels.create_case_manager),
  ];
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "case_managers":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };
  const [preCropImage, setPreCropImage] = useState("");
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [fileType, setFileType] = useState("");

  const inputs = [
    {
      name: "name",
      type: "text",
      label: "Name of the Case Manager",
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email of the Case Manager",
      required: true,
    },
    {
      name: "mobile",
      type: "text",
      label: "Phone no of the Case manager",
      required: true,
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      required: !id,
    },
  ];

  const preferences = [
    {
      name: "viewInformation",
      type: "checkbox",
      label: "Viewing Information ",
      required: true,
    },
    {
      name: "assignment",
      type: "checkbox",
      label: "Round Robin",
    },
  ];

  const cropImageData = (file) => {
    if (file.type.includes("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreCropImage(reader.result);
      };
      setOpenCropperModal(!openCropperModal);
      setFileType(file.type);
      reader.readAsDataURL(file);
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  };

  const uploadCroppedDate = (cropData, setFieldValue, setFieldError) => {
    const file = dataURLtoFile(cropData, fileType);
    s3Upload(file, "avatarUrl", setFieldValue, setFieldError);
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file.type.includes("image/")) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", {
            variant: "success",
          });
        }
      } catch (error) {
        setFieldError(field, "Could not upload this file");
      } finally {
        setLoader({ state: false });
        setOpenCropperModal(!openCropperModal);
      }
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    async function getCaseManagerDetails(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      const res = await CaseManagerService.getCMDetails(id);
      try {
        if (res?.caseManager) {
          console.log(res);
          console.log(res?.caseManager?.permissions + 123);
          setManagerDetail(res.caseManager);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    if (id) {
      getCaseManagerDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Loading..." });
      let payload = {
        ...values,
        permissions: {
          assignment: values?.assignment,
          viewInformation: values?.viewInformation,
        },
      };
      payload = _.omit(payload, ["assignment", "viewInformation", "id"]);
      if (id) {
        const response = await CaseManagerService.editCaseManager(payload, id);
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          navigate("/dashboard");
        }
      } else {
        const response = await CaseManagerService.createCaseManager(payload);
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          navigate("/dashboard");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <div>
        <Formik
          initialValues={{
            avatarUrl: managerDatail?.avatarUrl || "",
            name: managerDatail?.name || "",
            email: managerDatail?.email || "",
            mobile: managerDatail?.mobile || "",
            password: managerDatail?.password || "",
            assignment: managerDatail?.permissions?.assignment || false,
            type: id ? "EDIT" : "CREATE",
            viewInformation:
              managerDatail?.permissions?.viewInformation || false,
          }}
          validationSchema={createCaseManager}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            isValid,
            setFieldValue,
            setFieldError,
          }) => (
            <StyledForm>
              <Form>
                <Container>
                  <div className="mb30">
                    <Heading>
                      <BackArrow
                        onClick={() => navigate(`/dashboard`)}
                        src={require("../../../assets/images/backArrow.svg")}
                      />
                      {_.startCase(
                        id
                          ? labels.edit_case_manager
                          : labels.create_case_manager
                      )}
                    </Heading>
                  </div>
                  <ProfilePicContainer>
                    <ImageWrapper onClick={() => fileRef.current.click()}>
                      <ProfilePic
                        src={
                          values?.avatarUrl
                            ? values.avatarUrl
                            : require("../../../assets/images/userPlaceholder.svg")
                        }
                      ></ProfilePic>
                      <PickerIcon
                        src={require("../../../assets/images/imagePicker.svg")}
                      />
                    </ImageWrapper>
                  </ProfilePicContainer>
                  {inputs?.map((input, key) => (
                    <FormField
                      white
                      {...{
                        key,
                        input,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                      }}
                    />
                  ))}
                  <div className="mt15">
                    <FormLabel
                      style={{
                        fontFamily: theme.fonts.primaryFontSemiBold,
                        fontSize: 12,
                        color: COLORS.INPUT_LABEL,
                      }}
                    >
                      {"Case Manager Preference"}
                      <span className="required-star"> *</span>
                    </FormLabel>
                    <div
                      className="flex"
                      style={{ marginLeft: -9, position: "relative" }}
                    >
                      {preferences?.map((pre, index) => (
                        <div
                          className="mr10 flex center-center"
                          key={index}
                          style={{}}
                        >
                          <CustomCheckBox
                            checkboxmargin
                            disabled={pre?.disabled}
                            type={pre?.type}
                            value={values[pre?.name]}
                            checked={values[pre?.name]}
                            error={errors[pre?.name] && touched[pre?.name]}
                            helperText={touched[pre?.name] && errors[pre?.name]}
                            id={pre?.name}
                            name={pre?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ marginTop: 6 }}
                          />
                          <div
                            className="mr5 mt5"
                            style={{
                              fontFamily: theme.fonts.primaryFontSemiBold,
                            }}
                          >
                            {pre?.label}
                          </div>
                        </div>
                      ))}
                      {errors?.viewInformation && (
                        <HelperText className="t40 ml9">
                          {errors?.viewInformation}
                        </HelperText>
                      )}
                    </div>
                  </div>
                  <input
                    ref={fileRef}
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) =>
                      cropImageData(
                        e.target.files[0],
                        "avatarUrl",
                        setFieldValue,
                        setFieldError
                      )
                    }
                  />
                  <ImageCropper
                    {...{
                      openCropperModal,
                      setOpenCropperModal,
                      preCropImage,
                      setPreCropImage,
                    }}
                    onCropped={(data) =>
                      uploadCroppedDate(data, setFieldValue, setFieldError)
                    }
                  />
                  <PrimaryCTAButton className="mt20" onClick={handleSubmit}>
                    {id ? "Update Case Manager" : "Create Case Manager"}
                  </PrimaryCTAButton>
                </Container>
              </Form>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default CreateCaseManager;

const BackArrow = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;

const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`;

const PickerIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  object-fit: contain;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
`;
