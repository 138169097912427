import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import useCartCount from "../../../hooks/useCartCount";
import CasesTable from "../../common/CasesTable";
import _ from "lodash";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";

const CaseListing = () => {
  const location = useLocation();
  const { stats } = useCartCount();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!_.isEmpty(stats)) {
      if (searchParams.get("caseType")) {
        if (searchParams.get("caseType") !== "negotiation") {
          setCaseType(searchParams.get("caseType"));
        } else {
          setCaseType("mediation");
        }
      } else {
        if (!stats?.negotiationCount && stats?.mediationCount > 0) {
          setCaseType("mediation");
        } else {
          setCaseType("mediation");
        }
      }
    }
  }, [searchParams, stats]);

  return (
    <div>
      <ActionBar
        breadcrumbs={[_.startCase(labels.cases), _.startCase(caseType)]}
      />
      {caseType && (
        <CasesTable caseType={caseType} status={searchParams.get("status")} />
      )}
    </div>
  );
};

export default CaseListing;
