import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import { PageWrapper, StyledLabel } from "./styles";
import LifeCycle from "../../common/LifeCycle";
import CaseDetailsCard from "../../common/CaseDetails";
import CaseManager from "./common/CaseManager";
import BigBlueButton from "./common/BigBlueButton";
import MeetingAccess from "../CaseDetails/common/MeetingControl";
import RecordingControl from "../CaseDetails/common/RecordingControl";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  CLAIMENT_STR,
  CLAIMANT_LIFE_CYCLE,
  BOTH_PAID_NEGOTIATION,
  NEGOTIATION_REACHED
} from "../../../helpers/constants";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import NegotiationState from "./NegotiationState";
import DrawerTable from "../../internal/DrawerTable";
import AssignCaseManager from "./common/AssignCaseManagerModal";
import MediationState from "./MediationState";
import labels from "../../../helpers/labels.json";
import queryString from "query-string";
import AlertDialog from "../../common/Alert";
import theme from "../../../assets/theme";
import CaseCTA from "./common/CaseCTA";

export default function CaseDetails({ id }) {
  const [state, setState] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState(); // This state is to display the bread crum
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [caesLifeCycle, setCaseLifeCyle] = useState([]); // This state holds the case lifecycle
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [ownerId, setOwnerId] = useState(); // this state holds the ownerId
  const [respondentId, setRespondentId] = useState(); // THis state holds the respondent Id
  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const [negotiationRounds, setNegotiationRounds] = useState({}); // THis state holds the NegotiationRounds
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const [pendingRegistrationFee, setPendingRegistrationFee] = useState(false);
  const [openAssignCaseManager, setOpenAssignCaseManager] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [comments, setComments] = useState([]); // This is list of comments
  const [partyList, setPartyList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const MTRef = useRef();
  /**THis useEffect is Used to get the case information */
  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let case_response = { ...res };
          if (case_response.status !== "draft") {
            if (case_response.status === "pendingRegistrationFee") {
              setPendingRegistrationFee(true);
            }
            if (!case_response.respondentPartyId) {
              case_response.status = AWAITING_RESPONDANT;
            } else if (
              case_response.respondentStatus === "pending" ||
              case_response.respondentStatus === "declined"
            ) {
              case_response.status = RESPONDANT_ONBOARDED;
            } else if (
              case_response?.status === NEGOTIATION_REACHED &&
              case_response?.subscriptionKind === "adhoc"
            ) {
              case_response.status = BOTH_PAID_NEGOTIATION;
            }
          }
          setCaseDetails(case_response);
          setBreadcrumbs([
            _.startCase(labels.cases),
            _.startCase(case_response?.resolutionKind),
            case_response.title
          ]);
          if (case_response?.resolutionKind === "negotiation") {
            getCaseNegotiations(id);
          } else {
            getCaseMediation(id);
          }
          getparty(id);
          setCaseLifeCyle(JSON.parse(JSON.stringify(CLAIMANT_LIFE_CYCLE)));
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          setOwnerId(case_response.claimantPartyId);
          setRespondentId(case_response.respondentPartyId);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
        navigate("/dashboard/cases");
      } finally {
        setLoader({ state: false });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseNegotiations(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const case_response = await CaseService.negotiations(id);
        setNegotiationRounds(case_response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      } finally {
        setLoader({ state: false });
      }
    }

    async function getparty(id) {
      let params = {
        page: 1,
        perPage: 1000
      };
      const partyParam = `?${queryString.stringify(
        params
      )}&sort=partyRole&sortDirection=asc`;
      try {
        const party_list_res = await CaseService.getPartyListByCaseId(
          id,
          partyParam
        ); // This is for getting the party details based on the case
        if (party_list_res?.data) {
          setPartyList(party_list_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseMediation(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        let params = {
          page: 1,
          perPage: 1000
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const mediation_res = await CaseService.mediations(id, stringParams);
        if (mediation_res) {
          setComments(mediation_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      } finally {
        setLoader({ state: false });
      }
    }

    if (id && refreshDetails) {
      getCaseById(id);
      setRefreshDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails]);

  /** THis is useEffect is used to set the curretState based on the status **/
  useEffect(() => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some(el => el.key === caseDetails.status)) {
        setCurrentState(
          caesLifeCycle.find(el => el.key === caseDetails.status)
        );
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find(el => el.index === 2));
        } else {
          setCurrentState(caesLifeCycle.find(el => el.index === 1));
        }
      }
    }
  }, [caesLifeCycle, caseDetails, isRespondent]);

  const onBreadcrumbClick = selected => {
    switch (selected) {
      case _.startCase(labels.cases):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      case _.startCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  async function callReopenCase() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.updateCaseReopen(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  const onbuttonClick = type => {
    setOpen(true);
    switch (type) {
      case "REOPEN_CASE":
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Proceed",
          clickSecondarybtn: () => callReopenCase(),
          clickPrimaryBtn: () => setOpen(false),
          desc: `Click proceed to Reopen your case, Click cancel to Cancel your action`,
          heading: "Do you really want to Reopen your case?",
          buttonType: type,
          descriptionTextStyle: {
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }
        });
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper>
        <div>
          <CaseDetailsCard
            {...{
              setState,
              partyList
            }}
            caseDetails={caseDetails}
            pendingRegistrationFee={pendingRegistrationFee}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className="flex wrap">
            <div className="mr25 mt25">
              <CaseManager
                disabled={
                  caseDetails?.resolutionKind !== "negotiation" &&
                  (caseDetails?.status === "draft" ||
                    caseDetails?.caseManager?.disabled)
                }
                caseManager={caseDetails?.caseManager}
                {...{ ...caseDetails?.caseManager }}
                showRemove={
                  currentState?.index < 4 && caseDetails?.caseManager?.id
                }
                addCaseManager={() => setOpenAssignCaseManager(true)}
                removeCaseManager={() => setOpenAssignCaseManager(true)}
              />
            </div>
            {caseDetails?.requestedMediators?.length ? (
              <>
                {caseDetails?.requestedMediators
                  ?.filter(el => el?.id)
                  ?.map((mediator, index) => (
                    <div className="mr25 mt25">
                      <CaseManager
                        key={index}
                        caseManager={mediator}
                        title={
                          caseDetails?.resolutionKind === "mediation"
                            ? "Mediator"
                            : "Arbitrator"
                        }
                      />
                    </div>
                  ))}
              </>
            ) : null}

            {caseDetails?.resolutionKind !== "negotiation" &&
            caseDetails?.mediator ? (
              <div className="mr25 mt25">
                <BigBlueButton
                  id={caseDetails?.id}
                  caseDetails={caseDetails}
                  status={caseDetails?.status}
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind
                  )} Session`}
                />
              </div>
            ) : (
              <>
                {caseDetails?.resolutionKind &&
                  caseDetails?.resolutionKind !== "negotiation" &&
                  !caseDetails?.mediator && (
                    <div className="mr25 mt25">
                      <BigBlueButton
                        id={caseDetails?.id}
                        caseDetails={caseDetails}
                        status={caseDetails?.status}
                        type={`Join ${_.capitalize(
                          caseDetails?.resolutionKind
                        )} Session`}
                      />
                    </div>
                  )}
              </>
            )}
            {caseDetails?.resolutionKind &&
              caseDetails?.resolutionKind !== "negotiation" && (
                <div className="mr25 mt25">
                  <MeetingAccess
                    id={caseDetails?.id}
                    caseDetails={caseDetails}
                    status={caseDetails?.status}
                    {...{ state, setState, id, MTRef }}
                  />
                  <RecordingControl
                    id={caseDetails?.id}
                    caseDetails={caseDetails}
                    status={caseDetails?.status}
                    {...{ state, setState, id, MTRef }}
                  />
                </div>
              )}
          </div>
        </div>
        {caseDetails?.status !== "draft" && (
          <>
            <div style={{ marginTop: 28 }}>
              <StyledLabel>
                Case {caseDetails?.resolutionKind} Lifecycle
              </StyledLabel>
              <LifeCycle
                items={caesLifeCycle}
                selectedIndex={currentState?.index}
                isMediation={caseDetails?.resolutionKind !== "negotiation"}
                resolutionKind={caseDetails?.resolutionKind}
              />
            </div>
            <div style={{ marginTop: 28 }}>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <NegotiationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails
                  }}
                />
              ) : (
                <MediationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails,
                    comments,
                    setComments,
                    partyList
                  }}
                />
              )}
            </div>
            <div style={{ marginTop: 28 }}>
              <CaseCTA
                cta={
                  caseDetails?.resolutionKind !== "negotiation"
                    ? currentState.cta
                    : currentState?.cta?.filter(
                        cta => cta.type !== "MEDIATION_SUCCESS"
                      )
                }
                resolutionKind={caseDetails?.resolutionKind}
                disableButton={false}
                onbuttonClick={type => onbuttonClick(type)}
              />
            </div>
          </>
        )}
      </PageWrapper>
      <div>
        <AlertDialog
          isOpen={open}
          {...{ ...dialogData }}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold
          }}
        />
      </div>
      <DrawerTable
        {...{ state, setState, id, MTRef }}
        type={caseDetails?.resolutionKind}
        claimantId={caseDetails?.claimantParty?.ownerId}
        respondentId={caseDetails?.respondentParty?.ownerId}
      />
      <AssignCaseManager
        modal={openAssignCaseManager}
        setModal={setOpenAssignCaseManager}
        onAssignSuccess={() => {
          setRefreshDetails(true);
          setOpenAssignCaseManager(false);
        }}
        caseDetails={caseDetails}
      />
    </Fragment>
  );
}
