import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomFileUpload } from "../FormInputs";
import { getFileName } from "../../../helpers/functions";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";

export default function DocumentUploadModal({
  modal,
  setModal,
  handleUpload = () => {},
  url = "",
  setUrl = () => {},
  handleSuccess = () => {},
  file,
  fileRef
}) {
  useEffect(() => {
    if (modal) {
      setUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  return (
    <Modal open={modal}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Add Document</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Label>Document</Label>
          <CustomFileUpload
            error={false}
            setFile={file => handleUpload(file)}
            style={{ marginTop: 6 }}
            file={{ name: getFileName(url) }}
            value={file}
            ref={fileRef}
            type="file"
            accept=".pdf, .docx,"
          />
          <ButtonContainer>
            <PrimaryOutlinedCTAButton
              style={{ width: "45%" }}
              onClick={e => {
                e.preventDefault();
                setModal(false);
                setUrl("");
              }}
            >
              Cancel
            </PrimaryOutlinedCTAButton>
            <PrimaryCTAButton
              disabled={!url}
              style={{ width: "45%" }}
              onClick={e => {
                e.preventDefault();
                handleSuccess(false);
              }}
            >
              Upload Document
            </PrimaryCTAButton>
          </ButtonContainer>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;
